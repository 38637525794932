const QUESTIONNAIRE_TYPE = {
  QUESTIONNAIRE: "Questionnaire",
  QUESTIONNAIRE_ITEM: "QuestionnaireItem",
  QUESTIONNAIRE_ITEM_DIRECTIVE: "QuestionnaireItemDirective",
};

const QUESTIONNAIRE_VUEX = {
  MUTATIONS: {
    SET_ANSWERS: "answer/setAnswers",
  },
  GETTERS: {
    ALL: "answer/all",
    ANSWER: "answer/answer",
    COMPONENT: "component/components",
  },
  ACTIONS: {
    ANSWER: "answer/answer",
    COMPONENT_REGISTER: "component/register",
  },
};

export { QUESTIONNAIRE_TYPE, QUESTIONNAIRE_VUEX };
