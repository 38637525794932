<template>
  <div class="host">
    <div class="confirm-area">
      <section>
        <div class="btn-back" @click="back">
          <img src="https://mamab.jp/uploads/web_image/btn_change.png" />
        </div>
      </section>
      <div class="confirm">
        <div>
          <label>豪華景品プレゼント</label>
          <div>{{ answers.present }}</div>
        </div>
        <div>
          <label>お名前</label>
          <div>{{ name }}</div>
        </div>
        <div>
          <label>フリガナ</label>
          <div>{{ name_kana }}</div>
        </div>
        <div>
          <label>ご本人様の生年月日</label>
          <div>{{ birthday }}</div>
        </div>
        <div>
          <label>郵便番号</label>
          <div>{{ answers.postal }}</div>
        </div>
        <div>
          <label>都道府県</label>
          <div>{{ answers.pref }}</div>
        </div>
        <div>
          <label>市区町村</label>
          <div>{{ answers.addr1 }}</div>
        </div>
        <div>
          <label>番地</label>
          <div>{{ answers.addr2 }}</div>
        </div>
        <div>
          <label>建物・マンション・表札名</label>
          <div>{{ answers.addr3 }}</div>
        </div>
        <div>
          <label>電話番号</label>
          <div>{{ answers.tel }}</div>
        </div>
        <div>
          <label>つながりやすい時間帯</label>
          <div>{{ answers.contact_time }}</div>
        </div>
      </div>
    </div>
    <section class="btn-area">
      <img
        src="https://mamab.jp/uploads/web_image/btn_end.png"
        @click="apply"
        class="pointer"
        :class="{ disabled: isConnecting }"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.host {
  padding-bottom: 2.4rem;
}

.confirm-area {
  width: 94%;
  margin: 1rem auto 0;
  border: 1px solid #ffdbdb;
}

.confirm {
  margin-top: 1rem;
  border-radius: 4px;

  > div {
    font-size: 0.9rem;

    > label {
      color: #423c3d;
      margin-bottom: 0;
      background-color: #ffdbdb;
      width: 100%;
      padding: 6px 8px;

      + div {
        padding: 12px 8px;
      }
    }
  }
}

.btn-back {
  margin-top: 0.5rem;

  > img {
    width: 160px;
  }
}

.btn-area {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 10px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    max-width: 340px;
  }
}

//ボタン非活性
.host .btn-area img.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { QUESTIONNAIRE_VUEX } from "@/util/questionnaire/constant";
import MarketingCloud from "@/components/MarketingCloud.vue";
export default {
  data() {
    return {
      isConnecting: false,
    };
  },
  setup() {
    const store = useStore();
    const answers = store.getters[QUESTIONNAIRE_VUEX.GETTERS.ALL];

    //q_karadanotewater1による回答調整。step2に書くべきだが都道府県との兼ね合いでconfirmに記載。
    if (answers.q_karadanotewater1 === "現在利用している") {
      answers.q_karadanotewater2 = null;
      answers.q_karadanotewater3 = null;
      answers.q_karadanotewater5 = null;
      answers.q_karadanotewater7 = null;
      answers.q_karadanotewater8 = null;
    } else if (answers.q_karadanotewater1 === "以前利用していたが、解約した") {
      answers.q_karadanotewater2 = null;
      answers.q_karadanotewater4 = null;
      answers.q_karadanotewater5 = null;
      answers.q_karadanotewater6 = null;
      answers.q_karadanotewater8 = null;
    } else if (answers.q_karadanotewater1 === "利用していないが、興味がある") {
      answers.q_karadanotewater4 = null;
      answers.q_karadanotewater5 = null;
      answers.q_karadanotewater6 = null;
      answers.q_karadanotewater7 = null;
      answers.q_karadanotewater8 = null;
    } else if (answers.q_karadanotewater1 === "使ったことはない") {
      answers.q_karadanotewater2 = null;
      answers.q_karadanotewater4 = null;
      answers.q_karadanotewater5 = null;
      answers.q_karadanotewater6 = null;
      answers.q_karadanotewater7 = null;
    }

    const name = computed(() => answers.sei + " " + answers.mei);
    const name_kana = computed(
      () => answers.sei_katakana + " " + answers.mei_katakana
    );
    const birthday = computed(
      () =>
        answers.birthday.slice(0, 4) +
        "/" +
        answers.birthday.slice(4, 6) +
        "/" +
        answers.birthday.slice(-2)
    );

    return {
      answers,
      name,
      name_kana,
      birthday,
    };
  },
  mixins: [MarketingCloud],
  methods: {
    apply() {
      //2重送信防止
      if (this.isConnecting) {
        return;
      }
      this.isConnecting = true;

      // トラッキング(MarketingCloud)
      this.mcConfirmApply();

      // 「その他」の選択肢を「その他:入力内容」の形式にして変更
      this.formatOtherOption();

      this.axios
        .post("/apply", this.answers)
        .then((res) => {
          if (res.data.result) {
            this.$store.dispatch("campaign/applied", res.data.sponsors);
            this.$router.push("complete");
          }
        })
        .catch((err) => {
          if (err.response.data.errors === "duplicate") {
            // 重複
            this.$store.dispatch("campaign/applied");
            this.$store.dispatch("answer/clear");
            alert("既に応募があります");
            this.$router.push("mypage");
          } else {
            // 応募データ異常のためメールアドレス入力状態に応じてTOP/STEP1から再入力させる
            // ※PWAのキャッシュで動いている可能性があるため、NuxtRouterではなくlocation.href遷移でアプリをリロードさせる
            location.href = this.$store.getters[
              QUESTIONNAIRE_VUEX.GETTERS.ANSWER
            ]("email")
              ? "/step1"
              : "/top";
          }
        })
        .finally(() => {
          this.isConnecting = false;
        });
    },
    back() {
      this.$router.push("step1");
    },
    formatOtherOption() {
      if (
        Object.prototype.hasOwnProperty.call(this.answers, "q_power1") &&
        this.answers.q_power1 === "その他"
      ) {
        if (
          Object.prototype.hasOwnProperty.call(this.answers, "q_power1_another")
        ) {
          this.answers.q_power1 = `その他：${this.answers.q_power1_another}`;
          delete this.answers.q_power1_another;
        }
      }
    },
  },
};
</script>
